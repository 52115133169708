import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import PhoneImge from '../assets/img/phone_1.png';

const Container = styled('div')`
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #f1f6ff;
`;

const Title = styled('div')`
  font-weight: 600;
  font-size: 24px;
  margin: 30px 0;
`;

const Subtitle = styled('div')`
  font-weight: 400;
  font-size: 16px;
  margin: 0px 30px;
`;

const CenterText = styled('div')`
  display: inline-block;
  text-align: center;
`;

const LandscapeMode = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <CenterText>
        <img src={PhoneImge} alt="Logo" />
        <Title>{t('LandscapeTitle')}</Title>
        <Subtitle>{t('LandscapeText')}</Subtitle>
      </CenterText>
    </Container>
  );
};

export default LandscapeMode;
