/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import * as React from 'react';
// import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { setup } from 'goober';
import { createGlobalStyles } from 'goober/global';
import { shouldForwardProp } from 'goober/should-forward-prop';
import { polyfill } from 'seamless-scroll-polyfill';
import { SWRConfig } from 'swr';

import './assets/fonts/PFEncoreSansPro-Bold.woff2';
import './assets/fonts/PFEncoreSansPro-Light.woff2';
import './assets/fonts/PFEncoreSansPro-Medium.woff2';
import './assets/fonts/PFEncoreSansPro-Regular.woff2';

import LandscapeMode from './components/LandscapeMode';
import useAxiosProvider from './Providers/axiosProvider';
import { DevDataProvider } from './Providers/contextProvider';
import Router from './routing/router';

setup(
  React.createElement,
  undefined,
  undefined,
  // Transient props
  shouldForwardProp((prop) => prop[0] !== '$')
);

// Enable smooth scrolling

polyfill({
  forcePolyfill: true,
});

// eslint-disable-next-line react/prop-types
const Mobile = ({ children }) => {
  // @TODO talk with maro tell us the max-width
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  return isMobile && isPortrait ? children : null;
};
// eslint-disable-next-line react/prop-types
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  return isNotMobile || isLandscape ? children : null;
};

const GlobalStyles = createGlobalStyles`


@font-face {
  font-family:  "PF Encore Sans Pro";
  src: local('PF Encore Sans Pro'), url(./assets/fonts/PFEncoreSansPro-Regular.woff2) format('woff2')
}

@font-face {
    font-family: 'PF Encore Sans Pro';
    font-weight: 600;
    src: local('PF Encore Sans Pro'), url(./assets/fonts/PFEncoreSansPro-Medium.woff2) format('woff2')
}

@font-face {
    font-family: 'PF Encore Sans Pro';
    font-weight: bold;
    src: local('PF Encore Sans Pro'), url(./assets/fonts/PFEncoreSansPro-Bold.woff2) format('woff2')
}
@font-face {
  font-family: 'PF Encore Sans Pro';
  font-weight: light;
  src: local('PF Encore Sans Pro), url(./assets/fonts/PFEncoreSansPro-Light.woff2) format('woff2')
}
html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-y: hidden;
}

  html,
  body {
    height: 100%;
    font-family: 'PF Encore Sans Pro', sans-serif;
    margin: 0!important;
    overflow-x: hidden !important;
  }
  
  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  body .uwy.userway_p3 .uai {
    bottom: 80px !important;
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  table tr td:first-child {
    padding-right: 10px;
  }

  table tr td:last-child {
    padding-left: 10px;
  }

  table tr td:not(:first-child):not(:last-child) {
    padding-inline: 10px;
  }
  
`;

const App = () => {
  const instance = useAxiosProvider();

  // Commented out due to business request

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   const scriptText = document.createTextNode(
  //     '(function(d){var s = d.createElement("script");s.setAttribute("data-account", "zfEbIregtx");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)'
  //   );
  //   script.appendChild(scriptText);
  //   script.id = 'userway';
  //   document.head.appendChild(script);
  // });

  return (
    <SWRConfig
      value={{
        fetcher: (url) => instance.get(url).then((res) => res),
        suspense: true,
      }}
    >
      <DevDataProvider>
        <div>
          <GlobalStyles />
          <Mobile>
            <Router />
          </Mobile>
          <Default>
            <LandscapeMode />
          </Default>
        </div>
      </DevDataProvider>
    </SWRConfig>
  );
};

export default App;
