import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// @TODO We have not decided how to do localization with BE
// For now I would create a sceleton for localized text for static components
// Menu/Footer/Contact form in these folders
import translationEL from './locales/el';
import translationEN from './locales/en';

const resources = {
  en: translationEN,
  el: translationEL,
};

const options = {
  // order and from where user language should be detected
  order: ['querystring', 'localStorage'],
};
const languageDetector = new LanguageDetector(null, options);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // the detected/selected language is stored on localStorage(i18nextLng)
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    react: {
      // @TODO Change that when we make the loading page
      useSuspense: false,
      bindI18n: 'loaded languageChanged',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['u', 'a', 'b'],
    },
    lng: 'el',
    fallbackLng: 'el',
    supportedLngs: ['en', 'el'],
    // Allows "en-US" and "en-UK" to be implcitly supported when "en"
    // is supported
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    debug: process.env.NODE_ENV === 'development',
  });

export default i18n;
