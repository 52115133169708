/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

const About = loadable(() => import('../pages/About'));
const AboutPost = loadable(() => import('../components/about/AboutPost'));
const Apps = loadable(() => import('../pages/Apps'));
const City = loadable(() => import('../pages/City'));
const Collection = loadable(() => import('../pages/Collection'));
const CollectionsRouter = loadable(() => import('../components/CollectionsRouter'));
const Fallback = loadable(() => import('../components/ErrorBoundaryFallback'));
const Guide = loadable(() => import('../pages/Guide'));
const GuideCategory = loadable(() => import('../components/guide/GuideCategory'));
const GuidePost = loadable(() => import('../components/guide/GuidePost'));
const Home = loadable(() => import('../pages/home/Home'));
const Info = loadable(() => import('../pages/Info'));
const InfoPost = loadable(() => import('../components/info/InfoPost'));
const Intro = loadable(() => import('../pages/Intro'));
const Legal = loadable(() => import('../pages/Legal'));
const LegalPost = loadable(() => import('../components/legal/LegalPost'));
const Loading = loadable(() => import('../components/Loading'));
const Map = loadable(() => import('../pages/map/Map'));
const News = loadable(() => import('../pages/News'));
const Newsletter = loadable(() => import('../pages/newsletter/Newsletter'));
const PageNotFound = loadable(() => import('../components/errorPage/PageNotFound'));
const Services = loadable(() => import('../pages/Services'));
const Search = loadable(() => import('../pages/Search'));

const AppLayout = loadable(() => import('./appLayout'));
const HomeLayout = loadable(() => import('./homeLayout'));
const IntroLayout = loadable(() => import('./introLayout'));

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<IntroLayout />}>
        <Route
          path="/"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Intro />
              </Suspense>
            </ErrorBoundary>
          }
        />
      </Route>
      <Route element={<HomeLayout />}>
        <Route
          path="/home"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            </ErrorBoundary>
          }
        />
      </Route>
      <Route element={<AppLayout />}>
        <Route
          path="/about"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <About />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path=":postId"
            element={
              <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<Loading />}>
                  <AboutPost />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="/services"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Services />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/apps"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Apps />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/collections"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <CollectionsRouter />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path=":collectionId"
            element={
              <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<Loading />}>
                  <Collection />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="/city"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <City />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/city/news"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <News />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path="/city/news/:postId"
            element={
              <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<Loading />}>
                  <News />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="/guide"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Guide />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/guide/:categoryId"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <GuideCategory />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path=":postId"
            element={
              <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<Loading />}>
                  <GuidePost />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="/map"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Map />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/newsletter"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Newsletter />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/legal"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Legal />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path=":pageId"
            element={
              <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<Loading />}>
                  <LegalPost />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="/info"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Info />
              </Suspense>
            </ErrorBoundary>
          }
        >
          <Route
            path=":pageId"
            element={
              <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<Loading />}>
                  <InfoPost />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="/search"
          element={
            <ErrorBoundary FallbackComponent={Fallback}>
              <Suspense fallback={<Loading />}>
                <Search />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route path="/nodevice" element={<Fallback />} />
        <Route path="/nocontent" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
export default Router;
