const AuthTokens = (() => {
  let service;

  const getService = () => {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  };

  const setAccessToken = (token) => {
    localStorage.setItem('access_token', token);
  };

  const getAccessToken = () => localStorage.getItem('access_token');

  const getRefreshToken = () => localStorage.getItem('refresh_token');
  const getDeviceId = () => localStorage.getItem('devId');

  const clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('devId');
  };
  return {
    getService,
    setAccessToken,
    getAccessToken,
    getRefreshToken,
    getDeviceId,
    clearToken,
  };
})();

export default AuthTokens;
