/* eslint-disable no-param-reassign */
import { useTranslation } from 'react-i18next';
import { writeStorage } from '@rehooks/local-storage';
import axios from 'axios';

import AuthTokens from '../utils/AuthTokens';

// prettier-ignore
const useAxiosProvider = () => {
  const { i18n } = useTranslation();
  const axiosBaseUrl = process.env.REACT_APP_API_URL || '';

  const instance = axios.create({
    baseURL: axiosBaseUrl,
    timeout: 10000,
  });
 
  // Intercerptor that adds Bearer Token
  instance.interceptors.request.use(
    (config) => {
      const token = AuthTokens.getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers.common['Accept-Language'] = i18n.language;
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Interceptor for response that refreshs token
  
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      // eslint-disable-next-line no-underscore-dangle
      if (!originalRequest._retry) {
        
        try {
        const res = await axios.post(`${axiosBaseUrl}/token/`, {
          username: `device-${AuthTokens.getDeviceId()}`,
          password: `687359fc349eceb82f629698e5bfa3cd8e955c03`,
        });
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;
        writeStorage('access_token',res.data.access);
        writeStorage('refresh_token', res.data.refresh);
        axios.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
       window.location.reload(false);
        return axios(originalRequest);
        }catch(err){
           // console.log(Object.keys(error), error.message);
           if (err.response.status===401) {
            const responseCreate = await axios.post(`${axiosBaseUrl}/auth/devices/`, {
              username: `device-${AuthTokens.getDeviceId()}`,
              password: `687359fc349eceb82f629698e5bfa3cd8e955c03`,
            });
            writeStorage('access_token',responseCreate.data.access);
            writeStorage('refresh_token', responseCreate.data.refresh);
            writeStorage('introSeen', false);
            axios.defaults.headers.common.Authorization = `Bearer ${responseCreate.data.access}`;
            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true;
            window.location.reload(false);
            return axios(originalRequest);
           }
        
        }
       
        // eslint-disable-next-line no-underscore-dangle
      
        return axios(originalRequest);
        /*
        return axios
          .post(`${axiosBaseUrl}/token/`, {
            username: `device-${AuthTokens.getDeviceId()}`,
            password: `687359fc349eceb82f629698e5bfa3cd8e955c03`,
          })
          .then((res) => {
            // 1) put token to LocalStorage
            AuthTokens.setAccessToken(res.data.access);

            // 2) Change Authorization header
            axios.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;

            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          })
          */
      }

      // return Error object with Promise
      return Promise.reject(error);
    }
  );
  return instance;
};

export default useAxiosProvider;
