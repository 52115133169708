import { createContext, useContext, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

const defaultDevData = {
  config: {
    collections: [],
  },
};

const DevDataContext = createContext({ collections: defaultDevData, setCollections: () => {} });

// eslint-disable-next-line react/prop-types
export const DevDataProvider = ({ children }) => {
  const [devData] = useLocalStorage('devData', defaultDevData);
  const [collections, setCollections] = useState(devData.config.collections);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DevDataContext.Provider value={{ collections, setCollections }}>
      {children}
    </DevDataContext.Provider>
  );
};

export const useDevData = () => useContext(DevDataContext);
